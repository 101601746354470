<template>
	<div>
		<!-- 基本信息 -->
		<!-- <div style="text-align:right;height: 50px;display: flex;align-items: center;justify-content: flex-end;">
            <el-button icon="iconfont icon-baocun-icon" plain v-no-more-click class="blueColor" @click="save">
                <span class="buttonText">保存</span>
            </el-button>
        </div> -->
		<div class="content" v-loading="boxLoading">
			<div style="text-align: center">
				<!-- <h4>基础信息</h4> -->
				<div class="img_box">
					<img class="img_a" :src="imgData ? imgData : headImg" height="130" width="130" alt style="border-radius: 50%" />
					<div class="_img_hov">
						<div class="img_p" @click="qkPortrait">清空头像</div>
					</div>
				</div>
				<div class="upload-wrap">
					<span>{{ imgData ? '修改头像' : '上传头像' }}</span>
					<input type="file" style="cursor: pointer" @change="changeFile($event)" accept="image/gif, image/jpeg ,image/png," />
				</div>
				<p>格式：支持jpg、png</p>
			</div>
			<div class="right-content clearfix" v-loading="Loading">
				<p>
					<i class="yc_tive"></i>
					<span style="margin-left: 10px">基础信息</span>
				</p>
				<div class="bottom_btn dialog-wrap" style="margin-top: 10px">
					<div>
						<el-form
							:model="ruleForm"
							:rules="rules"
							ref="ruleForm"
							label-width="110px"
							class="demo-ruleForm"
							:label-position="'left'"
							:inline-message="false"
							style="padding: 20px 0 0 10px; box-sizing: border-box"
						>
							<el-form-item label="姓名" prop="name">
								<el-input v-model="ruleForm.name" @focus="clearInput('name')" class="" placeholder="请输入2-10字名字"></el-input>
							</el-form-item>
							<!-- <el-form-item label="学号/编号" prop="code" style="margin-left:12%;">
                                <el-input  v-model="ruleForm.code" placeholder="请输入编号" :readonly="true" type="text" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                            </el-form-item> -->
							<el-form-item label="性别" prop="sex" style="margin-left: 12%">
								<el-select v-model="ruleForm.sex" placeholder="请选择性别" style="width: 100%">
									<el-option v-for="(item, index) in sexOptions.options" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="出生年月" prop="birthday">
								<el-date-picker
									style="width: 100%"
									v-model="ruleForm.birthday"
									value-format="yyyy-MM-dd"
									type="date"
									:picker-options="pickerOptions0"
									:editable="false"
									placeholder="选择生日"
								></el-date-picker>
							</el-form-item>
							<el-form-item label="民族" style="margin-left: 12%">
								<el-input v-model="ruleForm.nation" placeholder="请输入民族"></el-input>
							</el-form-item>
							<el-form-item label="籍贯">
								<el-input v-model="ruleForm.native_place" placeholder="请输入籍贯"></el-input>
							</el-form-item>
							<el-form-item label="所属运动队" prop="department_uuid" style="margin-left: 12%">
								<el-cascader
									clearable
									style="width: 100%"
									:collapse-tags="true"
									v-model="ruleForm.department_uuid"
									placeholder="请选择所属运动队"
									:options="$store.state.treeMenu"
									:props="{
										label: 'name',
										value: 'uuid',
										multiple: true,
									}"
								></el-cascader>
							</el-form-item>
							<el-form-item label="证件号" class="selects" style="">
								<div class="select_content">
									<el-select v-model="ruleForm.identity_type" class="first-child-focus">
										<el-option label="身份证号" :value="1"></el-option>
										<el-option label="驾驶证号" :value="2"></el-option>
										<el-option label="军官证号" :value="3"></el-option>
									</el-select>
									<el-input placeholder="请输入证件号" @focus="clearInput('identity_number')" v-model="ruleForm.identity_number" class="input-with-select" style="background: #fff">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item label="学历" style="float: right; margin-right: 8%; margin-left: 12%">
								<el-select clearable v-model="ruleForm.education" placeholder="请选择学历" style="width: 100%">
									<el-option v-for="(item, index) in educationJson" :key="index" :label="item.name" :value="item.type"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="岗位" prop="station_uuid">
								<el-select clearable v-model="ruleForm.station_uuid" placeholder="请选择岗位" @change="changeStation" style="width: 100%">
									<el-option v-for="(item, index) in jobsOptions.options" :key="index" :label="item.name" :value="item.uuid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-if="formItemShow" prop="is_emphasis" label="是否为重点运动员" style="float: right; margin-right: 8%; margin-left: 12%">
								<el-radio v-model="ruleForm.is_emphasis" :label="1">否</el-radio>
								<el-radio v-model="ruleForm.is_emphasis" :label="2">是</el-radio>
							</el-form-item>
							<div class="clearfix"></div>
							<!-- <el-form-item label="籍贯" prop="native_place" style="margin-left:12%;">
                                <el-input v-model="ruleForm.native_place" placeholder="请输入籍贯"></el-input>
                            </el-form-item> -->

							<!-- <el-form-item label="岗位"
                            prop="station_uuid"
                            style="margin-left:0;width:92%;">
                            <el-select clearable
                                    v-model="ruleForm.station_uuid"
                                    placeholder="请选择岗位"
                                    style="width:100%;"
                                    @change="changeStation">
                            <el-option v-for="(item,index) in jobsOptions.options"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.uuid"></el-option>
                            </el-select>
                        </el-form-item> -->

							<el-form :model="ruleSport" :rules="rules" ref="ruleSport" label-width="80px" class="demo-ruleForm" :label-position="'left'" :inline-message="false">
								<el-form-item label="身高" v-show="formItemShow" style="margin-left: 0%" v-if="$store.state.address == '西安' || $store.state.address == '江西'">
									<el-input v-model="ruleSport.height" placeholder="请输入身高" v-enterNumbers @blur="setHeightValue()">
										<i slot="suffix" style="font-style: normal">cm</i>
									</el-input>
								</el-form-item>
								<el-form-item label="身高" v-show="formItemShow" style="margin-left: 0%" v-else>
									<el-input v-model="ruleSport.height" placeholder="请输入身高" v-enterNumbers maxlength="20" @blur="setHeightValue()">
										<i slot="suffix" style="font-style: normal">cm</i>
									</el-input>
								</el-form-item>
								<el-form-item label="体重" v-show="formItemShow" style="margin-left: 12%" v-if="$store.state.address == '西安' || $store.state.address == '江西'">
									<el-input v-model="ruleSport.weight" placeholder="请输入体重" v-enterNumbers maxlength="20">
										<i slot="suffix" style="font-style: normal">kg</i>
									</el-input>
								</el-form-item>
								<el-form-item label="体重" v-show="formItemShow" style="margin-left: 12%" v-else>
									<el-input v-model="ruleSport.weight" placeholder="请输入体重" v-enterNumbers maxlength="20">
										<i slot="suffix" style="font-style: normal">kg</i>
									</el-input>
								</el-form-item>
								<el-form-item label="级别" style="margin-left: 0%" v-show="formItemShow">
									<el-select v-model="ruleSport.grade" placeholder="请选择级别" style="width: 100%">
										<el-option v-for="(item, k) in levelOptions.options" :key="k" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="教练" v-show="formItemShow" style="margin-left: 12%">
									<el-input maxlength="10" v-model="ruleSport.coach_name" @focus="clearInput('coach_name')" placeholder="请输入教练姓名"></el-input>
								</el-form-item>
								<!-- <div style="width:92%;" class="clearfix">
                                    <div style="width:43.5%;float: left;"> -->
								<el-form-item label="项目" prop="sport_uuid" v-show="formItemShow" style="margin-left: 0%">
									<!-- <el-select v-model="ruleSport.sport_uuid" placeholder="请选择项目" style="width:100%;" @change="selectTypeArr">
                                        <el-option v-for="(item, k) in typeOptions.options" :key="k" :label="item.name" :value="item.uuid"></el-option>
                                    </el-select> -->
									<el-select v-model="ruleSport.sport_uuid" placeholder="请选择项目" clearable filterable style="width: 100%" @change="selectTypeArr">
										<el-option v-for="(item, k) in typeOptions.options" :key="k" :label="item.name" :value="item.uuid"> </el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="专项" v-show="formItemShow" style="margin-left: 12%" v-if="$store.state.address == '西安' || $store.state.address == '江西'">
									<el-select v-model="ruleSport.sport_event_uuid" placeholder="请选择专项" style="width: 100%">
										<el-option v-for="(item, k) in projectOptions.options" :key="k" :label="item.name" :value="item.uuid"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="专项" v-show="formItemShow" style="margin-left: 12%" v-else>
									<el-select v-model="ruleSport.sport_event_uuid" multiple placeholder="请选择专项" style="width: 100%">
										<el-option v-for="(item, k) in projectOptions.options" :key="k" :label="item.name" :value="item.uuid"></el-option>
									</el-select>
								</el-form-item>
								<!-- ------LLLLL -->
								<!-- <el-form-item label="就读年级" v-show="formItemShow" style="margin-left:0%;">
                                    <el-input v-model="ruleSport.study_grade" placeholder="请输入就读年级"></el-input>
                                </el-form-item> -->
								<!-- <el-form-item label="培训时间" v-show="formItemShow" style="margin-left:12%;">
                                    <el-date-picker v-model="ruleSport.train_date" style="width: 100%;" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item> -->
								<!-- <el-form-item label="输送区县" style="margin-left:0%;" v-show="formItemShow">
                                    <el-input v-model="ruleSport.transport_area" placeholder="请输入输送区县"></el-input>
                                </el-form-item>
                                <el-form-item label="输送教练" style="margin-left:12%;" v-show="formItemShow">
                                    <el-input v-model="ruleSport.transport_coach" placeholder="请输入输送教练"></el-input>
                                </el-form-item> -->
								<el-form-item label="运动年限" v-show="formItemShow" class="clearfix" style="margin-left: 0%" v-if="$store.state.address == '西安' || $store.state.address == '江西'">
									<el-input v-model="ruleSport.sport_year" placeholder="请输入运动年限" style="width: 100%"></el-input>
								</el-form-item>
								<el-form-item label="运动年限" v-show="formItemShow" class="clearfix" style="margin-left: 0%" v-else>
									<el-input v-model="ruleSport.sport_year" placeholder="请输入运动年限" style="width: 100%"></el-input>
								</el-form-item>
								<el-form-item label="入队时间" v-show="formItemShow" style="margin-left: 12%" v-if="$store.state.address == '西安' || $store.state.address == '江西'">
									<el-date-picker style="width: 100%" v-model="ruleSport.in_time" value-format="yyyy-MM-dd" type="date" :editable="false" placeholder="选择入队时间"></el-date-picker>
								</el-form-item>
								<el-form-item label="入队时间" v-show="formItemShow" style="margin-left: 12%" v-else>
									<el-date-picker style="width: 100%" v-model="ruleSport.in_time" value-format="yyyy-MM-dd" type="date" :editable="false" placeholder="选择入队时间"></el-date-picker>
								</el-form-item>
								<el-form-item label="常驻地址" v-show="formItemShow" style="margin-left: 0%; width: 92%">
									<el-input v-model="ruleSport.permanent_address" placeholder="请输入常驻地址"></el-input>
								</el-form-item>
							</el-form>

							<el-form-item label="是否离队" style="width: 100%">
								<el-radio v-model="ruleForm.is_out" :label="1">离队</el-radio>
								<el-radio v-model="ruleForm.is_out" :label="0">未离队</el-radio>
							</el-form-item>
							<el-form-item label="离队时间" v-if="ruleForm.is_out">
								<el-date-picker style="width: 100%" v-model="ruleForm.out_time" value-format="yyyy-MM-dd" type="date" :editable="false" placeholder="选择离队时间"></el-date-picker>
							</el-form-item>
							<el-form-item label="离队去向" v-if="ruleForm.is_out" style="margin-left: 12%">
								<el-input v-model="ruleForm.out_go" placeholder="请输入离队去向"></el-input>
							</el-form-item>
							<el-form-item label="是否生成帐号" style="margin-left: 0">
								<el-radio v-model="ruleForm.is_create_account" :label="true">是</el-radio>
								<el-radio v-model="ruleForm.is_create_account" :label="false">否</el-radio>
							</el-form-item>
						</el-form>
					</div>
					<p style="padding: 2rem 0 0 0; overflow: hidden" class="bot_content clearfix">
						<i class="yc_tive"></i>
						<span style="margin-left: 10px">联系方式</span>
					</p>
					<div>
						<el-form
							:model="ruleFormNext"
							:rules="rules"
							ref="ruleFormNext"
							label-width="80px"
							class="demo-ruleForm"
							:label-position="'left'"
							style="padding: 30px 0 0 10px; box-sizing: border-box"
						>
							<el-form-item label="手机号">
								<el-input maxlength="11" @focus="clearInput('mobile')" v-model="ruleFormNext.mobile" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item label="邮箱" style="margin-left: 12%">
								<el-input v-model="ruleFormNext.email" @focus="clearInput('email')" placeholder="请输入邮箱"></el-input>
							</el-form-item>
							<!-- <div class="selects" v-if="!ruleFormNext.contacts.length">
                              <el-form-item label="紧急联系人">
                                  <div class="select_content">
                                    <el-select v-model="item.emergency_relation" placeholder="请选择关系" style="width:100px;" clearable class="first-child-focus" slot="prepend">
                                        <el-option v-for="(item, k) in between.options" :key="k" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                    <el-input v-model="item.emergency_name" maxlength="40" placeholder="请输入紧急联系人" style="background:#fff;" class="input-with-select">
                                    </el-input>
                                  </div>
                                </el-form-item>
                                <el-form-item :rules="{
                                          required: false,
                                          message: '手机号输入错误',
                                          trigger: 'blur',
                                          validator: validateTelphone,
                                        }" :prop="'contacts.' + index + '.emergency_contact'" label="联系方式" style="margin-left:12%;">
                                    <el-input v-enterNumber v-model="item.emergency_contact" placeholder="请输入联系方式" maxlength="40"></el-input>
                                </el-form-item>
                            </div> -->
							<div v-for="(item, index) in ruleFormNext.contacts" :key="index" class="selects">
								<el-form-item label="紧急联系人">
									<div class="select_content">
										<el-select v-model="item.emergency_relation" placeholder="请选择关系" style="width: 100px" clearable class="first-child-focus" slot="prepend">
											<el-option v-for="(item, k) in between.options" :key="k" :label="item.label" :value="item.value"> </el-option>
										</el-select>
										<el-input
											v-model="item.emergency_name"
											maxlength="40"
											@focus="clearInput('emergency_name')"
											placeholder="请输入紧急联系人的姓名"
											style="background: #fff"
											class="input-with-select"
										>
										</el-input>
									</div>
								</el-form-item>

								<el-form-item
									label="联系方式"
									:rules="{
										required: false,
										message: '手机号输入错误',
										trigger: 'blur',
										validator: validateTelphone,
									}"
									style="margin-left: 12%"
								>
									<el-input v-enterNumber v-model="item.emergency_contact" @focus="clearInput('emergency_contact')" placeholder="请输入联系方式" maxlength="11"></el-input>
								</el-form-item>
							</div>
						</el-form>
						<!-- <el-button style="position: fixed;top:83%;left:20%;" @click="addContacts">添加</el-button>
                        <el-button style="position: fixed;top:88%;left:20%;" @click="delContacts">删除</el-button>  -->
					</div>
				</div>
				<div class="bot_btn">
					<el-button round @click="save">
						<span>保存</span>
					</el-button>
					<el-button round @click="recent">
						<span>返回</span>
					</el-button>
				</div>
			</div>
			<div class="reBack">
				<span @click="reBack"> <i class="iconfont iconfanhui"></i>返回 </span>
			</div>
		</div>
		<!-- <div class="content-inner" :key="index" v-for="(experience, index) in resumeData.workExperiences" >
    <el-form-item :prop="'workExperiences.' + index + '.company'" label="公司" label-position="top" :rules="rules.experienceRule.company">
        <el-input class="input-item" v-model="experience.company"></el-input>
    </el-form-item>
</div> -->
	</div>
</template>
<script>
import img from './../../../static/head-default-big.jpg'
let data = {
	name: '', //姓名
	sex: '', //性别
	code: '', //编号
	birthday: '', //出生年月
	birthday: '',
	height: '',
	weight: '',
	nation: '', //民族
	department_uuid: '', //运动队
	station_uuid: '', //岗位
	grade: '', //级别
	sport_uuid: '', //运动类
	sport_event_uuid: [], //运动项
	in_time: '', //入队时间
	native_place: '', //籍贯
	identity_type: '', //证件类型
	identity_number: '', //证件号
	coach_name: '', //教练
	sport_year: '', //年限
	permanent_address: '', //常驻地址,
	out_time: '', //离队时间
	out_go: '', //离队去向
	is_create_account: false, //是否生成帐号
	is_out: 0,
	education: '',
	is_emphasis: 1,
}

let dataNext = {
	mobile: '', //手机号
	email: '', //邮箱
	emergency_name1: '', //紧急联系人1名称
	emergency_relation1: '', //紧急联系人1关系
	emergency_contact1: '', //紧急联系人1手机号
	emergency_name2: '',
	emergency_relation2: '',
	emergency_contact2: '',
}
let dataSport = {
	height: '', //身高
	weight: '', //体重
	grade: '', //等级
	coach_name: '', //教练名称
	sport_uuid: '', //项目
	sport_event_uuid: [], //专项
	sport_year: '', //运动年限
	in_time: '', //入队时间
	permanent_address: '', //常驻地址
}

export default {
	data() {
		let validateTelphone = (rule, value, callback) => {
			if (!/^1[345789]\d{9}$/.test(value) && value != '') {
				callback(new Error('请输入正确的手机号'))
			} else {
				callback()
			}
		}

		return {
			studyGradeData: [
				{
					value: 1,
					label: '初一',
				},
				{
					value: 2,
					label: '初二',
				},
				{
					value: 3,
					label: '初三',
				},
				{
					value: 4,
					label: '四年级',
				},
				{
					value: 5,
					label: '五年级',
				},
				{
					value: 6,
					label: '六年级',
				},
				{
					value: 7,
					label: '高一',
				},
				{
					value: 8,
					label: '高二',
				},
				{
					value: 9,
					label: '高三',
				},
				{
					value: 10,
					label: '中一',
				},
				{
					value: 11,
					label: '中二',
				},
				{
					value: 12,
					label: '中三',
				},
				{
					value: 13,
					label: '中专毕业',
				},
				{
					value: 14,
					label: '大一',
				},
				{
					value: 15,
					label: '大二',
				},
				{
					value: 16,
					label: '大三',
				},
				{
					value: 17,
					label: '未读',
				},
			],
			educationJson: [
				{
					name: '博士',
					type: '1',
				},
				{
					name: ' 硕士',
					type: '2',
				},
				{
					name: '本科',
					type: '3',
				},
				{
					name: '专科',
					type: '4',
				},
				{
					name: '其他',
					type: '5',
				},
			],
			headImg: img,
			activeName: '',
			bondArr: ['父亲', '母子', '兄弟', '姐妹', '配偶', '儿女'],
			userData: null,
			validateTelphone: validateTelphone,
			sexOptions: {
				options: [
					{
						value: 1,
						label: '男',
					},
					{
						value: 2,
						label: '女',
					},
				],
				value: '',
			},
			departmentOptions: {
				options: [],
			},
			jobsOptions: {
				options: [],
			},
			levelOptions: {
				options: [
					{
						value: 1,
						label: '国际健将',
					},
					{
						value: 2,
						label: '运动健将',
					},
					{
						value: 3,
						label: '一级',
					},
					{
						value: 4,
						label: '二级',
					},
					{
						value: 5,
						label: '三级',
					},
					{
						value: 6,
						label: '无',
					},
				],
			},
			between: {
				options: [
					{
						value: 1,
						label: '父亲',
					},
					{
						value: 2,
						label: '母亲',
					},
					{
						value: 3,
						label: '兄弟姐妹',
					},
					{
						value: 4,
						label: '配偶',
					},
					{
						value: 5,
						label: '子女',
					},
					{
						value: 6,
						label: '其他',
					},
				],
			},
			typeOptions: {
				options: [],
			},
			projectOptions: {
				options: [],
			},
			lationshipOptions: {
				options: [],
			},

			pickerOptions0: {
				disabledDate(time) {
					return time.getTime() > Date.now() - 8.64e6 //如果没有后面的-8.64e6就是不可以选择今天的
				},
			},

			ruleForm: {
				...data,
			},
			ruleFormNext: {
				...dataNext,
				contacts: [
					{
						name: '紧急联系人',
						num: 1,
						phoneName: '联系方式',
						emergency_relation: '',
						emergency_contact: '',
						emergency_name: '',
					},
				],
				// contacts:[
				//     {emergency_contact:[{
				//     validator: validateTelphone, trigger: 'blur' ,
				//     required:false
				// }]}
				// ]
			},
			ruleSport: {
				...dataSport,
			},
			rules: {
				education: { ...this.$rules.noEmpty, message: '学历不能为空' },
				name: { ...this.$rules.noEmpty, message: '姓名不能为空' },
				id: { ...this.$rules.noEmpty, message: 'id不能为空' },
				// code: { ...this.$rules.noEmpty, message: '编号不能为空' },
				sex: { ...this.$rules.noEmpty, message: '性别不能为空' },
				age: { ...this.$rules.noEmpty, message: '年龄不能为空' },
				birthday: { ...this.$rules.noEmpty, message: '出生年月不能为空' },
				mobile: [
					{
						validator: validateTelphone,
						trigger: 'blur',
						required: false,
					},
				],
				emergency_contact: [
					{
						validator: validateTelphone,
						trigger: 'blur',
						required: false,
					},
				],
				department_uuid: {
					...this.$rules.noEmpty,
					message: '所属运动队不能为空',
				},
				permanent_address: {
					...this.$rules.noEmpty,
					message: '常驻不能为空',
				},
				station_uuid: {
					...this.$rules.noEmpty,
					message: '岗位不能为空',
				},
				sport_uuid: {
					...this.$rules.noEmpty,
					message: '项目不能为空',
				},
				sport_event_uuid: {
					...this.$rules.noEmpty,
					message: '专项不能为空',
				},
				birthday: {
					...this.$rules.noEmpty,
					message: '出生日期不能为空',
				},
				height: { ...this.$rules.noEmpty, message: '身高不能为空' },
				weight: { ...this.$rules.noEmpty, message: '体重不能为空' },
				grade: { ...this.$rules.noEmpty, message: '级别不能为空' },
				level: { ...this.$rules.noEmpty, message: '级别不能为空' },
				in_time: {
					...this.$rules.noEmpty,
					message: '入队时间不能为空',
				},
				nation: { ...this.$rules.noEmpty, message: '民族不能为空' },
				native_place: {
					...this.$rules.noEmpty,
					message: '籍贯不能为空',
				},
				email: this.$rules.email,
				emergency_name1: {
					...this.$rules.noEmpty,
					message: '紧急联系人不能为空',
					trigger: 'blur',
				},
				emergency_relation1: {
					...this.$rules.noEmpty,
					message: '联系人关系不能为空',
				},
				emergency_contact1: this.$rules.phone,
				emergency_name2: {
					...this.$rules.noEmpty,
					message: '紧急联系人不能为空',
					trigger: 'blur',
				},
				emergency_relation2: {
					...this.$rules.noEmpty,
					message: '联系人关系不能为空',
				},
				emergency_contact2: this.$rules.phone,
				coach_name: {
					...this.$rules.noEmpty,
					message: '教练名称不能为空',
				},
				sport_year: {
					...this.$rules.noEmpty,
					message: '运动年限不能为空',
				},
				// identity_number: {
				//     ...this.$rules.noEmpty,
				//     message: '证件号不能为空',
				// },
				identity_number: [
					{
						pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
						message: '请输入合法身份证号',
						trigger: 'blur',
					},
				],
				is_emphasis: {
					...this.$rules.noEmpty,
					message: '请选择是否为重点运动员',
				},
			},
			addUserDialogStep2: false,
			imgData: '',
			tableDataSelectArr: [],
			imgFile: null,
			qkimgfile: null, //清空头像
			addUserDialog: false,
			formItemShow: false,
			boxLoading: false,
			Loading: false,
		}
	},
	created() {
		// this.imgData =  `${this.$store.state.url}/dist/static/head-default-big.jpg`;
		this.getSelectData()
		this.$store.state.breadcrumbArr = ['人事管理', '组织结构', this.$route.query.uuid ? '编辑人员' : '新增人员']
	},
	mounted() {
		let time = setInterval(() => {
			if (this.$store.state.treeMenu != null) {
				// this.ruleForm.department_uuid = [[this.$store.state.treeMenu[0].uuid]]
				clearInterval(time)
			}
		}, 300)
	},
	methods: {
		setHeightValue() {
			if (this.ruleSport.height < 0) {
				this.ruleSport.height = ''
				return this.$message({
					message: '您输入的结果值可能太小哦！',
					type: 'error',
				})
			} else if (this.ruleSport.height > 300) {
				this.ruleSport.height = ''
				return this.$message({
					message: '您输入的结果值可能太大哦！',
					type: 'error',
				})
			}
		},
		reBack() {
			this.$router.go(-1)
		},
		recent() {
			this.$router.go(-1)
		},
		changeStation(uuid, type) {
			let stationText = ''
			this.jobsOptions.options.forEach((i, k) => {
				if (i.uuid == uuid) {
					stationText = i.name
				}
			})
			if (stationText == '运动员') {
				this.formItemShow = true
				setTimeout(() => {
					this.$refs['ruleSport'].clearValidate()
					this.ruleForm.is_emphasis = 1
				}, 0)
				if (!type) {
					this.ruleForm.is_create_account = true
				}
			} else {
				this.formItemShow = false
				if (!type) {
					this.ruleForm.is_create_account = false
				}
			}
		},
		addUserDialogClick() {
			this.addUserDialog = true
			this.typeOptions.options.forEach((i, k) => {
				if (i.uuid == this.ruleForm.sport_uuid) {
					this.projectOptions.options = i.children
				}
			})
			setTimeout(() => {
				this.$dialogHeight()
			}, 0)
		},

		getData() {
			this.$axios
				.post(
					'/p/staff/info',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.Loading = true
						res.data.data[0].is_emphasis = Number(res.data.data[0].is_emphasis)
						switch (res.data.data[0].education) {
							case 1:
								res.data.data[0].education = '博士'
								break
							case 2:
								res.data.data[0].education = ' 硕士'
								break
							case 3:
								res.data.data[0].education = '本科'
								break
							case 4:
								res.data.data[0].education = '专科'
								break
							case 5:
								res.data.data[0].education = '其他'
								break
						}
						this.userData = res.data.data[0]
						this.imgData = res.data.data[0].head_img ? `http://${res.data.data[0].head_img}` : ''
						this.ruleForm = {
							...res.data.data[0],
							is_emphasis: Number(res.data.data[0].is_emphasis),
							birthday: res.data.data[0].birthdays,
							sport_event_uuid: res.data.data[0].sport_event_uuid != '' ? res.data.data[0].sport_event_uuid.split(',') : [],
						}
						this.ruleSport = {
							...res.data.data[0],
							sport_event_uuid: res.data.data[0].sport_event_uuid != '' ? res.data.data[0].sport_event_uuid.split(',') : [],
						}
						this.ruleFormNext = { ...res.data.data[0] }
						this.ruleFormNext.contacts = res.data.data[0].emergency_con
						if (!this.ruleFormNext.contacts.length) {
							this.ruleFormNext.contacts.push({
								emergency_contact: '',
								emergency_name: '',
								emergency_relation: '',
							})
						}

						let time = setInterval(() => {
							if (this.typeOptions.options.length > 0) {
								clearInterval(time)

								let sport_uuid = true

								this.typeOptions.options.forEach((i, k) => {
									if (i.uuid == this.ruleForm.sport_uuid) {
										sport_uuid = false
										this.projectOptions.options = i.children
									}
								})
								if (sport_uuid) {
									this.$joinArray(
										[
											{
												name: this.ruleForm.sport_name,
												uuid: this.ruleForm.sport_uuid,
											},
										]
										// this.jobsOptions.options,
										// console.log(this.ruleForm.sport_name)
									)
								}
								this.changeStation(res.data.data[0].station_uuid, true)
								setTimeout(() => {
									this.ruleForm.is_emphasis = Number(res.data.data[0].is_emphasis)
								}, 0)

								this.$joinArray(
									[
										{
											// name: this.ruleForm.station_name,
											name: this.ruleForm.station_name,
											uuid: this.ruleForm.station_uuid,
										},
									],
									this.jobsOptions.options
								)
								if (this.projectOptions.options.length > 0) {
									let arr = []
									for (let i in this.ruleForm.sport_event_name_uuid) {
										arr = arr.concat({
											name: i,
											uuid: this.ruleForm.sport_event_name_uuid[i],
										})
									}
									setTimeout(() => {
										this.projectOptions.options.forEach((i, k) => {
											for (let j in arr) {
												if (i.uuid == arr[j].uuid) {
													arr.splice(j, 1)
												}
											}
										})
										this.$joinArray(arr, this.projectOptions.options)
									}, 100)
								}
							}
						}, 300)
						setTimeout(() => {
							this.Loading = false
						}, 1000)
					}
				})
		},
		// 划过删除头像
		qkPortrait() {
			console.log('删除')
			this.qkimgfile = true
			this.imgData = this.headImg
		},
		//
		changeFile(e) {
			this.qkimgfile = null

			function getObjectURL(file) {
				var url = null
				if (window.createObjectURL != undefined) {
					// basic
					url = window.createObjectURL(file)
				} else if (window.URL != undefined) {
					// mozilla(firefox)
					url = window.URL.createObjectURL(file)
				} else if (window.webkitURL != undefined) {
					// webkit or chrome
					url = window.webkitURL.createObjectURL(file)
				}
				return url
			}

			let imgData = e.target.files[0]
			this.imgFile = imgData
			this.imgData = getObjectURL(imgData)
		},
		/*弹出框，下一步*/
		dialogNext() {
			let submit = () => {
				this.addUserDialog = false
				this.addUserDialogStep2 = true
			}
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					submit()
				} else {
					return false
				}
			})
		},
		save() {
			let stationText = ''
			this.jobsOptions.options.forEach((i, k) => {
				if (i.uuid == this.ruleForm.station_uuid) {
					stationText = i.name
				}
			})

			var realLength = 0
			var len = this.ruleForm.out_go.length
			var charCode = -1
			for (var i = 0; i < len; i++) {
				charCode = this.ruleForm.out_go.charCodeAt(i)
				if (charCode >= 0 && charCode <= 128) {
					realLength += 1
				} else {
					// 如果是中文则长度加2
					realLength += 2
				}
			}
			if (realLength > 50) {
				return this.$message({
					type: 'error',
					message: '离队去向最大长度为50',
				})
			}

			if (!this.$sishiLength(this.ruleForm.name)) {
				return this.$message({
					type: 'error',
					message: '姓名最大长度为40',
				})
			}

			if (this.ruleForm.nation != '' && !this.$sishiLength(this.ruleForm.nation)) {
				return this.$message({
					type: 'error',
					message: '民族最大长度为40',
				})
			}

			if (this.ruleForm.native_place != '' && !this.$sishiLength(this.ruleForm.native_place)) {
				return this.$message({
					type: 'error',
					message: '籍贯最大长度为40',
				})
			}

			if (this.ruleForm.identity_number != '' && !this.$sishiLength(this.ruleForm.identity_number)) {
				return this.$message({
					type: 'error',
					message: '证件号最大长度为40',
				})
			}

			if (this.ruleForm.coach_name != '' && !this.$sishiLength(this.ruleForm.coach_name)) {
				return this.$message({
					type: 'error',
					message: '教练最大长度为40',
				})
			}

			if (this.ruleSport.sport_year != '' && !this.$sishiLength(this.ruleSport.sport_year)) {
				return this.$message({
					type: 'error',
					message: '运动年限最大长度为40',
				})
			}

			if (this.ruleSport.permanent_address != '' && !this.$sishiLength(this.ruleSport.permanent_address)) {
				return this.$message({
					type: 'error',
					message: '常驻地址最大长度为40',
				})
			}

			if (this.ruleFormNext.email != '' && !this.$sishiLength(this.ruleFormNext.email)) {
				return this.$message({
					type: 'error',
					message: '邮箱最大长度为40',
				})
			}

			if (this.ruleFormNext.email != '' && !this.$sishiLength(this.ruleFormNext.email)) {
				return this.$message({
					type: 'error',
					message: '紧急联系人最大长度为40',
				})
			}

			let submit = () => {
				let department_uuid = []
				this.ruleForm.department_uuid.forEach((i, k) => {
					department_uuid.push(i[i.length - 1])
				})
				switch (this.ruleForm.education) {
					case '博士':
						this.ruleForm.education = '1'
						break
					case ' 硕士':
						this.ruleForm.education = '2'
						break
					case '本科':
						this.ruleForm.education = '3'
						break
					case '专科':
						this.ruleForm.education = '4'
						break
					case '其他':
						this.ruleForm.education = '5'
						break
				}

				let data = {
					name: this.ruleForm.name,
					sex: this.ruleForm.sex,
					birthday: this.ruleForm.birthday,
					birthday: this.ruleForm.birthday ? this.ruleForm.birthday : '',
					nation: this.ruleForm.nation,
					station_uuid: this.ruleForm.station_uuid,
					native_place: this.ruleForm.native_place,
					identity_type: this.ruleForm.identity_type,
					identity_number: this.ruleForm.identity_number,
					mobile: this.ruleFormNext.mobile == '' ? '' : this.ruleFormNext.mobile,
					email: this.ruleFormNext.email,
					department_uuid: department_uuid.join(','),
					is_out: this.ruleForm.is_out,
					out_time: this.ruleForm.out_time ? this.ruleForm.out_time : '',
					out_go: this.ruleForm.out_go,
					is_create_account: this.ruleForm.is_create_account,
					emergency_con: JSON.stringify(this.ruleFormNext.contacts),
					education: this.ruleForm.education,
					is_emphasis: this.formItemShow ? this.ruleForm.is_emphasis : '',
				}
				// data.code = this.ruleForm.code;
				if (this.$route.query.uuid) {
					data.uuid = this.$route.query.uuid
				}

				if (stationText == '运动员') {
					data = {
						...data,
						height: this.ruleSport.height,
						weight: this.ruleSport.weight,
						grade: this.ruleSport.grade ? this.ruleSport.grade : '',
						coach_name: this.ruleSport.coach_name,
						sport_uuid: this.ruleSport.sport_uuid,
						sport_event_uuid: this.ruleSport.sport_event_uuid.join(','),
						sport_year: this.ruleSport.sport_year,
						in_time: this.ruleSport.in_time ? this.ruleSport.in_time : '',
						permanent_address: this.ruleSport.permanent_address,
						// study_grade: this.ruleSport.study_grade ? this.ruleSport.study_grade : '',
						// train_date: this.ruleSport.train_date ? this.ruleSport.train_date : '',
						// transport_area: this.ruleSport.transport_area ? this.ruleSport.transport_area : '',
						// transport_coach: this.ruleSport.transport_coach ? this.ruleSport.transport_coach : '',
					}
				}

				// for (let i in data) {
				//     if (data[i] == "" || data[i] == null) {
				//         delete data[i];
				//     }
				// }

				let formData = new FormData()
				if (this.imgFile) {
					formData.append('head_img', this.imgFile)
				}
				if (this.qkimgfile) {
					formData.append('head_img', false)
				}
				for (let i in data) {
					formData.append(i, data[i])
				}
				this.$axios
					.post(this.$route.query.uuid ? '/p/staff/update' : '/p/staff/add', formData)
					.then(res => {
						if (res.data.code == 0) {
							this.$refs.ruleFormNext.resetFields()
							this.$utils.message()
							if (this.$route.query.uuid) {
								this.$router.push({
									path: `/management/eRecord/architecMore/${this.$route.query.uuid}`,
								})
							} else {
								this.$router.push({ path: '/management/architecture' })
							}
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
			let subType = true
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
				} else {
					subType = false
					return false
				}
			})
			this.$refs['ruleFormNext'].validate(valid => {
				if (valid) {
				} else {
					subType = false
					return false
				}
			})
			if (stationText == '运动员') {
				this.$refs['ruleSport'].validate(valid => {
					if (valid) {
					} else {
						subType = false
						return false
					}
				})
			}
			if (subType) {
				submit()
			}
		},
		selectTypeArr(val) {
			this.typeOptions.options.forEach((i, k) => {
				if (i.uuid == val) {
					this.projectOptions.options = i.children
				}
			})
			this.ruleSport.sport_event_uuid = []
			// this.selectArr.project = "";
		},

		getSelectData() {
			this.$axios
				.all([
					this.$axios.post(
						'/p/staff/sport',
						this.$qs({
							uuid: this.$route.query.uuid,
							flag: 1,
						})
					),
					this.$axios.post('/p/staff/tree'),
					this.$axios.post(
						'/p/station/show',
						this.$qs({
							staff_uuid: this.$route.query.uuid,
						})
					),
				])
				.then(
					this.$axios.spread((res1, res2, res3) => {
						this.departmentOptions.options = res2.data.data.department
						this.typeOptions.options = res1.data.data
						this.jobsOptions.options = res3.data.data
						// console.log(this.typeOptions.options);

						// a = res3.data.data
						// a = a.slice(0,-1);
						// console.log(a);
						// this.jobsOptions.options = a;
						// console.log(this.jobsOptions.options);
						// this.ruleSport.sport_uuid = res1.data.data[0].uuid;
						// this.ruleSport.sport_event_uuid = res1.data.data[0][0].uuid;

						// this.ruleSport.sport_uuid = this.typeOptions.options[0].uuid;
						this.selectTypeArr(res1.data.data[0].uuid)
						// this.ruleSport.sport_event_uuid = [this.projectOptions.options[0].uuid]

						if (this.$route.query.uuid) {
							this.getData()
						} else {
							this.boxLoading = true
							this.$axios.post('/p/staff/getCode').then(res => {
								if (res.data.code == 0) {
									this.ruleForm.code = res.data.data
									this.$nextTick(() => {
										setTimeout(() => {
											this.$refs['ruleForm'].clearValidate()
											this.boxLoading = false
										}, 1000)
									})
								} else {
									setTimeout(() => {
										this.boxLoading = false
									}, 3000)
								}
							})
						}
					})
				)
		},

		clearInput(name) {
			if (name == 'identity_number' || name == 'name') {
				if (this.ruleForm[name].indexOf('*') !== -1) {
					this.$set(this.ruleForm, name, '')
				}
			} else if (name == 'emergency_contact' || name == 'emergency_name') {
				if (this.ruleFormNext.contacts[0][name].indexOf('*') !== -1) {
					this.$set(this.ruleFormNext.contacts[0], name, '')
				}
			} else if (name == 'coach_name') {
				if (this.ruleSport[name].indexOf('*') !== -1) {
					this.$set(this.ruleSport, name, '')
				}
			} else {
				if (this.ruleFormNext[name].indexOf('*') !== -1) {
					this.$set(this.ruleFormNext, name, '')
				}
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.select_content {
	display: flex;
	/deep/ .el-select {
		width: 120px;
		margin-right: 10px;
	}
}
.selects /deep/ .el-input-group__prepend {
	background-color: #fff;
}

.demo-ruleForm {
	.el-form-item {
		width: 40%;
		float: left;
		margin-bottom: 30px;
	}

	// .el-form-item:nth-child(even) {
	//   margin-left: 12%;
	// }
}

.buttonText {
	padding: 0 0 0 6px;
	color: #888;
}

.content {
	display: flex;
	margin-left: -30px;
	justify-content: space-between;
	background: #fff;
	padding-top: 50px;

	& > div:nth-child(1) {
		flex: 1.8;

		h4 {
			font-size: 24px;
			color: #626262;
			// border-bottom: 1px dashed #979797;
			padding: 0 0 10px 0;
			position: relative;
			width: 74%;
			margin: 0 auto;
			margin-bottom: 20px;
		}

		h4::after {
			border: 0px solid #fff; // 主要是这句
			background: transparent;
			display: block;
			content: ' ';
			// height: 100%;
			// width: 100%;
			position: absolute;
			top: 100%;
			left: 53%;
			transform: translateX(-50%) translateY(-0%);
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to right, #ccc 0%, #979797 40%, transparent 50%);
			background-size: 8px 1px;
			background-repeat: repeat-x;
		}

		p {
			color: #b8c4ce;
			font-size: 16px;
			line-height: 30px;
			margin: 10px 0;
			margin-top: 0px;
		}

		button {
			border: 1px solid #4bb3ff;
			color: #4bb3ff;
		}

		.upload-wrap {
			width: 100%;
			height: 40px;
			position: relative;
			text-align: center;
			cursor: pointer;
			overflow: hidden;

			span {
				z-index: 1;
				line-height: 40px;
				color: #4bb3ff;
				font-size: 18px;
			}

			input {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				z-index: 2;
			}
		}
	}

	& > div:nth-child(2) {
		flex: 7;
	}

	.left-head-img {
		float: left;

		img {
			border-radius: 50%;
		}

		margin-right: 24px;
	}

	.right-content {
		overflow: hidden;

		.yc_tive {
			display: inline-block;
			width: 5px;
			height: 17px;
			background: #0055e9;
			border-radius: 12px;
		}

		.bot_btn {
			text-align: center;
			padding-bottom: 20px;
			.el-button:nth-child(1) {
				width: 120px;
				background: #0055e9;
				color: #fff;
				margin-right: 100px;
			}
			.el-button:nth-child(2) {
				width: 120px;
				background: #fff;
				border: 1px solid #0055e9;
				color: #0055e9;
			}
		}

		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}

		.item {
			width: 33.333%;
			float: left;
			margin-top: 30px;

			div:nth-child(1) {
				width: 9px;
				height: 9px;
				border-radius: 50%;
				margin-right: 14px;
				background: #fc940a;
				float: left;
				margin-top: 4px;
			}

			div:nth-child(2) {
				float: left;
				font-size: 16px;
			}
		}
	}
}

.dialog-wrap {
	& > div:nth-child(2) {
		width: 55%;
		display: inline-block;
		position: relative;
		margin-left: 60px;
		z-index: 2;
	}

	& > div:nth-child(2)::after {
		border: 1px solid #fff; // 主要是这句
		background: transparent;
		display: block;
		content: ' ';
		// height: 100%;
		// width: 100%;
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 45%;
		transform: translateX(-50%) translateY(-50%);
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, #ccc 0%, #e3e3e3 30%, transparent 50%);
		background-size: 1px 8px;
		background-repeat: repeat-y;
	}
}

.el-form-item__content .el-button:nth-child(1) {
	margin: 0;
}

/deep/ .bottom_btn {
	.el-input .el-input__inner {
		border-radius: 0;
		border-right-width: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.el-input .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select {
		float: left;
	}

	.el-select .el-input__inner {
		border-right-width: 0;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid #ccc;
	}

	.el-select .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select .el-input__inner:focus {
		border-right-width: 1px;
	}

	.bot_content {
		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}
	}
}
.reBack {
	padding-right: 50px;
	font-size: 16px;
	color: #666;
	i {
		margin-right: 5px;
	}
	span {
		&:hover {
			cursor: pointer;
		}
	}
}
</style>
<style lang="scss">
.img_box {
	position: relative;
}

.img_p {
	width: 160px;
	height: 160px;
	display: none;
	background-color: rgba(0, 0, 0, 0.8);
	position: absolute;
	top: 0;
	left: 25%;
	color: #fff;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	// z-index: 99;
	line-height: 160px;
	margin-left: -25% !important;
	border-radius: 50%;
}
._img_hov {
	width: 180px;
	height: 160px;
	// background: olivedrab;
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -25% !important;
}
._img_hov:hover .img_p {
	display: block;
}
</style>
